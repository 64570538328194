<template>
    <v-footer fixed dark class="fixed-bottom grey darken-3">
        <v-container>
            <v-row>
                <v-col cols="12" sm="6">
                    <p>© 2021. Mediana Services Limited</p>
                </v-col>
                <v-col cols="12" sm="6" class="d-flex justify-end">
                    <a href="https://mediana.kz/docfile/%D0%BF%D0%BE%D0%BB%D0%B8%D1%82%D0%B8%D0%BA%D0%B0.pdf" class="politics white--text">Политика компании</a>
                </v-col>
            </v-row>
        </v-container>
    </v-footer>
</template>

<script>
export default {
    
}
</script>

<style scoped>
.politics{
    font-family: cursive;
    text-decoration: none;
}
</style>