<template>
    <div class="grey lighten-4">
        <v-container>
            <v-row>
                <v-col cols="12" sm="6">
                    <img src="../assets/logo_mediana_services.png">
                </v-col>
                <v-col cols="12" sm="6" class="d-flex justify-end">
                    <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                            dark text
                            class="red darken-1"
                            v-bind="attrs"
                            v-on="on">
                            {{ language[selectLang] }}
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item
                            link
                            v-for="(item, index) in language"
                            :key="index"
                            @click="changeLanguage(index)"
                            >
                        <v-list-item-title>{{ item }}</v-list-item-title>
                        </v-list-item>
                        </v-list>
                    </v-menu>
                    <router-link to="/list" class="login" v-if="isAuth">
                        <v-btn
                            depressed
                            class="red darken-1" 
                            dark
                        >
                            {{ $t('allList') }}
                        </v-btn>
                    </router-link>
                    <router-link to="/" class="login" v-if="isAuth">
                        <v-btn
                            depressed
                            class="red darken-1" 
                            dark
                        >
                            {{ $t('todayList') }}
                        </v-btn>
                    </router-link>
                    <router-link to="/login" class="login" v-if="isAuth">
                        <v-btn
                            depressed
                            class="red darken-1" 
                            dark
                            @click="logout"
                        >
                            {{ $t('logout') }}
                        </v-btn>
                    </router-link>
                    <router-link to="/login" class="login" v-if="!isAuth">
                        <v-btn
                            depressed
                            class="red darken-1" 
                            dark
                        >
                            {{ $t('login') }}
                        </v-btn>
                    </router-link>
                </v-col>
            </v-row>  
        </v-container>
    </div>
</template>

<script>
export default {
    data(){
        return{
            language: {
                'kk': 'KZ',
                'ru': 'RU'
            }
        }
    },
    computed:{
        isAuth(){
            return this.$store.getters.isAuth
        },
        selectLang(){
            return this.$store.getters.getLanguage;
        }
    },
    methods:{
        logout(){
            this.$store.commit('logout');
        },
        changeLanguage($event){
           this.$store.commit('setLang', $event);
           location.reload();
        }
    }
}
</script>

<style scoped>
img{
    width: 150px;
}
.login{
    text-decoration: none;
}
.red{
    margin-right: 10px;
}
</style>