<template>
    <v-app>
        <v-pagination
            v-model="currPage"
            class="my-4"
            :length="totalPage"
            :total-visible="7"
            :v-bind:click="changePage(currPage)"

        >

        </v-pagination>
    </v-app>
</template>
<script>

export default {
    props: ['total', 'item'],
    data(){
        return{
            currPage: 1
        }
    },
    computed:{
        totalPage() {
            if(this.total){
                return Math.ceil(this.total / this.item)
            }
            else{
                return 1
            }
            
        }
    },
    methods:{
        changePage(pageNumber){
            this.currPage = pageNumber
            this.$emit('page-changed', pageNumber)
        }
    }
}
</script>

<style scoped>
.pagination{
    padding: 40px;
}
.red{
   border-radius: 10px;
   width: 30px;
}
.active{
    color: white;
    border-radius: 10px;
}
ul li{
    display: inline;
    margin-right: 30px;
}
</style>