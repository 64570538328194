<template>
    <v-app>
        <v-container class="main">
            <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="time"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
                <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="time"
                    :label="$t('chooseTime')"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
                </template>
                <v-date-picker
                v-model="time"
                no-title
                scrollable
                >
                <v-spacer></v-spacer>
                <v-btn
                    text
                    color="primary"
                    @click="menu = false"
                >
                    {{ $t('cancel') }}
                </v-btn>
                <v-btn
                    text
                    color="primary"
                    @click="$refs.menu.save(time), getDate(time)"
                >
                    {{ $t('ok') }}
                </v-btn>
                </v-date-picker>
            </v-menu>
             <v-btn
                depressed
                class="red darken-1" 
                dark
                @click="reloadPage()"
            >
               {{ $t('today') }} 
            </v-btn>
            <v-simple-table dense style="margin-bottom: 100px; max-width:90%;">
                <template v-slot:default>
                <thead>
                    <tr>
                    <th class="text-left">
                        {{ $t('name') }}
                    </th>
                    <th class="text-left">
                        {{ $t('position') }}
                    </th>
                    <th class="text-left" >
                        {{ $t('firstEnterTime') }}
                    </th>
                    <th class="text-left" >
                        {{ $t('timeOfLate') }}
                    </th>
                    <th class="text-left" >
                        {{ $t('timeOfLeave') }}
                    </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(person, index) in data" :key="index">
                        <td style="height: 56px;">{{ person.name }}</td>
                        <td>{{ person.notes }}</td>
                        <td><p :class="colors(person.color)" class="d-flex py-1 px-1 clock_st" style="width: 120px;">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-clock" viewBox="0 0 16 16">
                                <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z"/>
                                <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z"/>
                            </svg>
                            {{ person.times }}</p>
                        </td>
                        <td><p :class="remainder(person.remain)" class="d-flex py-1 px-1 clock_st" style="width: 250px;">
                         {{ getTime(person.remain) }}</p>
                        </td>
                        <td><p v-if="person.lastEntry" class="d-flex py-1 px-1 clock_st" style="background: #00ACC1;">
                         {{ person.lastEntry }}</p>
                        </td>
                    </tr> 
                </tbody>
                </template>
            </v-simple-table>
            </v-container>
    </v-app>
</template>
<script>
import axios from 'axios';
import Header from '../components/Header'
import Footer from '../components/Footer'
import Pagination from './pagination.vue';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/scss/index.scss';

export default {
    components:{
        Header,
        Footer,
        Pagination,
        DatePicker,
    },
    data(){
        const current = new Date();
        return{
            data: {},
            menu: false,
            time: new Date().toJSON().slice(0,10).replace(/-/g,'-'),
            color: '#f3693d red accent-3',
            color1: '#6adc6e teal lighten-2',
            colorAfter: 'red darken-2',
            colorOnTime: 'green darken-1',
            currDate: `${current.getFullYear()}-${current.getMonth()+1}-${current.getDate()}`,
            array: [
                {
                    name: '11'
                },
                {
                    name: '12'
                },
                {
                    name: '13'
                },
            ],
            na: '',
            before: '',
            timeHour: '',
            late: '',
            lateMin: '',
            onTime: ''
        }
    },
    mounted(){
        this.getDate(this.time);
        
    },
    methods:{  
        colors($event){
            if($event == 'red'){
                return this.color
            }else if($event == 'green'){
                return this.color1
            }
        },
        remainder($event){
            if($event > 0){
                return this.colorOnTime
            }else if($event< 0){
                return this.colorAfter
            }else if($event == 0){
                return this.colorOnTime
            }
        },
        getTime($event){
            if($event> 0){
                if($event<59){
                return this.na + $event + this.before
                }else{
                    return this.na+ Number(Math.abs($event)/60).toFixed(0) + this.timeHour + Math.abs($event)%60 + this.before
                }
            }else if($event<0){
                if($event>-59){
                    return this.late+ Math.abs($event) + this.lateMin
                }else{
                    return this.late + (Number(Math.abs($event)/60)-0.5).toFixed(0) + this.timeHour + Math.abs($event)%60 + this.lateMin
                }
            }else if($event == 0){
                return this.onTime
            }
        },
        getDate($event){
            var self = this;
            this.time = $event;
            axios.post(`http://add.pass.mediana.kz/api/v1/getBewardDaysEntry?token=`+localStorage.getItem("token"),
                    { date: self.time } 
                )
                .then(response => { 
                    if (response.status === 200)
                        this.data = response.data;
                    }
                )
                .catch(error => console.log(error))
        },
        getCurrentDate(){
            var self = this;
            axios.post(`http://add.pass.mediana.kz/api/v1/getBewardDaysEntry?token=`+localStorage.getItem("token"),
                    { date: self.currDate } 
                )
                .then(response => { 
                    if (response.status === 200)
                        this.data = response.data;
                    }
                )
                .catch(error => console.log(error))
        },
        reloadPage(){
            window.location.reload();
        }
    }  ,
    created(){
        this.na = this.$t('na')
        this.before = this.$t('before')
        this.timeHour = this.$t('timeHour')
        this.late = this.$t('late')
        this.lateMin = this.$t('lateMin')
        this.onTime = this.$t('onTime')
    }  
}
</script>
<style scoped>
.main{
    padding-top: 50px;
}
.v-input{
        max-width: 30% !important;
}
.clock_st{
    border-radius: 4px;
    justify-content: center;
    color: #fff;
    box-shadow: 0 8px 6px -6px black;
    margin: 5px;
}
.clock_st svg{
    margin-right: 15px;
}
.red{
    margin: 10px 0px 20px 0px;
}
</style>